export default function WatchVideoBtn(el, {
	actions,
    events,
	markup,
}) {
	el.addEventListener('click', () => {
		console.log('Watch video button clicked');
		events.emit(actions.loadModal, { markup: markup });
	});
}
